import React from 'react'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import PageConfig from '@component/layouts/PageConfig/PageConfig'
import useScreenLog from '@hook/useScreenLog'

const Custom404: React.FC = () => {
    const router = useRouter()
    const {t} = useTranslation()
    const {} = useScreenLog({pageName: 'page_not_found'})

    return (
        <PageConfig mainClassName={'bg-white dark:bg-bg_dark_background'}>
            <section className="flex items-center h-full p-16">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-wmd text-center">
                        <Text className="mb-8 text-[80px] font-extrabold text-gray04 dark:text-dark_gray04">
                            {t('404Page.title')}
                        </Text>
                        <Text className="text-2xl font-bold md:text-3xl text-gray01 dark:text-dark_gray01">
                            {t('404Page.subtitle')}
                        </Text>
                        <Text className="mt-4 mb-8 text-gray01 dark:text-dark_gray01">{t('404Page.desc')}</Text>
                        <div className={'flex justify-center'}>
                            <WizButton
                                text={t('404Page.back.title')}
                                buttonType={'primary'}
                                size={'large'}
                                onClick={() => router.push('/')}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </PageConfig>
    )
}

export const getStaticProps = async ({locale}) => ({
    props: {
        ...(await serverSideTranslations(locale, ['common'])),
    },
})

export default Custom404
